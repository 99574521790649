// Header.tsx

import React, {useState} from "react";
import {Layout} from "antd";
import NavBar from "../Navbar/Navbar";
import AlertForm from "../Alert/Alert";

const {Header} = Layout;

const AOHeader: React.FC = () => {
    const [alertMessage, setAlertMessage] = useState("");
    const [alertClassName, setAlertClassName] = useState("d-none");
    return (
        <Header
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                background: '#dcd8cc'
            }}>
            <NavBar />
            <AlertForm message={alertMessage} className={alertClassName} />
        </Header>
    );
};

export default AOHeader;