// IngredientManagement.tsx

import React, {ChangeEvent, useEffect, useState} from "react";
import {Ingredient} from "./Types/IngredientType";
import axios from "axios";
import {Alert, Container} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import FoodForm from "./Form/AddTable";
import SimpleInput from "./Form/SimpleInput";



const addIngredient = async (name: string, tier: number):Promise<void> => {
    try {
        const response = await axios.post<Ingredient>('https://api-ao.mancert.org/api/v1/addingredient', {
            name,
            tier,
        });
    } catch (error) {
        console.log(error);
        throw error;
    }
}

const editIngredient = async (name: string, tier: number, id: number):Promise<void> => {
    try {
        const response = await axios.post<Ingredient>('https://api-ao.mancert.org/api/v1/editingredient', {
            id,
            name,
            tier,
        });
    } catch (error) {
        console.log(error)
        throw error
    }
}

const deleteIngredient = async (id: number, name: string, tier: number):Promise<void> => {
    try {
        const response = await axios.post<Ingredient>('https://api-ao.mancert.org/api/v1/deletingredient', {
            id,
            name,
            tier,
        });
    } catch (error) {
        console.log(error)
        throw error
    }
}

const IngredientManagement: React.FC = () => {
    const [ ingredients, setIngredients ] = useState<Ingredient[]>([])
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [tier, setTier] = useState(1);
    const [error, setError] = useState<string | null>(null);

    const fetchIngredients = async ()=> {
        setLoading(true);
        try {
            const response = await axios.get('https://api-ao.mancert.org/api/v1/ingredient');
            setIngredients(response.data);
        }catch (error) {
            setError('unable to fetch ingredients');
            setLoading(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchIngredients()
    }, []);

    const handleAddClick = async () => {
        setLoading(true);
        if (!name) {
            setError('Name is required');
            setLoading(false);
            return;
        }

        if (isNaN(tier)) {
            setError("Tier must be a valid number");
            setLoading(false);
            return;
        }

        try {
            await addIngredient(name, tier)
            await fetchIngredients();
            setLoading(false);
            setError(null);
        }catch (error) {
            setError('unable to remove ingredient for some reason:')
            setLoading(false);
        }
        setLoading(false);
    };

    if (loading) {
        return <p>Loading items...</p>;
    }

    const handleInputChange = (id: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setIngredients(prevIngredient =>
            prevIngredient.map(f =>
                f.id === id ? { ...f, [name]: name === "tier" ? parseInt(value) : value } : f
            )
        );
    };

    const handleSaveButton = async (id: number, name: string, tier: number) => {
        setLoading(true);
        try {
            await editIngredient(name, tier, id);
            await fetchIngredients();
        } catch (error) {
            setError("unable to update ingredient")
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteButton = async (id: number, name: string, tier: number) => {
        setLoading(true);
        try {
            await deleteIngredient(id, name, tier);
            await fetchIngredients();
        } catch (error) {
            setError("unable to delete ingredient")
        } finally {
            setLoading(false);
        }
    }

    return(
        <Container className='mt-5'>
            <h2>Add Ingredient</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <FoodForm
                name={name}
                tier={tier}
                setName={setName}
                setTier={setTier}
                handleAddClick={handleAddClick}
                label={"Ingredient Name"}
            />
            <h3>Manage Ingredients</h3>
            <Table hover responsive size={'sm'}>
                <thead>
                <tr>
                    <th style={{ width: '75%' }}>Name</th>
                    <th>Tier</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {ingredients ? (
                    ingredients.map(ingredient => (
                        <tr key={ingredient.id}>
                            <td>
                                <SimpleInput
                                    name="name"
                                    type="text"
                                    value={ingredient.name}
                                    onChange={(e) => handleInputChange(ingredient.id, e)}
                                />
                            </td>
                            <td>
                                <SimpleInput
                                    name="tier"
                                    type="number"
                                    value={ingredient.tier}
                                    min="1"
                                    max="8"
                                    onChange={(e) => handleInputChange(ingredient.id, e)}
                                />
                            </td>
                            <td>
                                <button
                                    className='btn btn-outline-primary me-1'
                                    onClick={(e) =>handleSaveButton(ingredient.id, ingredient.name, ingredient.tier)}>
                                    Save
                                </button>
                                <button
                                    className='btn btn-outline-danger me-1'
                                    onClick={(e) =>handleDeleteButton(ingredient.id, ingredient.name, ingredient.tier)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))
                ): (
                    <div className="container">
                        <h5>no ingredients on record</h5>
                    </div>
                )}

                </tbody>
            </Table>
        </Container>
    )
}

export default IngredientManagement;