import React, { ChangeEvent, FC } from 'react';
import { Table } from 'react-bootstrap';

interface FoodFormProps {
    name: string;
    tier: number;
    setName: (name: string) => void;
    setTier: (tier: number) => void;
    handleAddClick: () => void;
    label: string;
}

const FoodForm: FC<FoodFormProps> = ({ name, tier, setName, setTier, handleAddClick, label }) => {
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleTierChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTier(parseInt(event.target.value, 10));
    };

    return (
        <Table>
            <thead>
            <tr>
                <th style={{ width: '75%' }}></th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="name">{label}</span>
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                </td>
                <td>
                    <div className="input-group mb-2">
                        <span className="input-group-text" id="tier">Tier</span>
                        <input
                            type="number"
                            min="1"
                            max="8"
                            className="form-control"
                            name="tier"
                            value={tier}
                            onChange={handleTierChange}
                        />
                    </div>
                </td>
                <td>
                    <div className="input-group mb-2">
                        <button
                            className="btn btn-outline-primary"
                            onClick={handleAddClick}>
                            Add
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </Table>
    );
};

export default FoodForm;
