import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Select, Table, InputNumber, Alert, Row, Col, Typography } from "antd";
import { Food, Recipe, RecipeDetails } from "./Types/FoodTypes";
import { Ingredient} from "./Types/IngredientType";
import TableIngredient from "./Tables/TableIngredient";
import {useAuthContext} from "./Auth/AuthContext";

const { Title, Text } = Typography;
const { Option } = Select;

const LandingPage: React.FC = () => {
    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [foods, setFoods] = useState<Food[]>([]);
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [selectedRecipe, setSelectedRecipe] = useState<RecipeDetails | null>(null);
    const [stock, setStock] = useState<{ [key: number]: number }>({});
    const [costs, setCosts] = useState<{ [key: number]: number }>({});
    const [productionRun, setProductionRun] = useState<number>(1);
    const [kickback, setKickback] = useState<number>(1);
    const [itemsProduced, setItemsProduced] = useState<number>(1);
    const [sellPrice, setSellPrice] = useState<number>(0);
    const [craftingCost, setCraftingCost] = useState<number>(0);
    const [messageText, setMessageText] = useState<string | null>(null);
    const saveTimeout = useRef<NodeJS.Timeout | null>(null);
    const {isAuthenticated} =  useAuthContext()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [recipeResponse, foodResponse, ingredientResponse] = await Promise.all([
                    axios.get<Recipe[]>(`${process.env.REACT_APP_API_URL}/recipe`),
                    axios.get<Food[]>(`${process.env.REACT_APP_API_URL}/food`),
                    axios.get<Ingredient[]>(`${process.env.REACT_APP_API_URL}/ingredient`),
                ]);
                setRecipes(recipeResponse.data);
                setFoods(foodResponse.data);
                setIngredients(ingredientResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleRecipeChange = (value: number) => {
        console.log('Selected recipe ID:', value);
        const foodId = value;
        const recipeIngredients = recipes.filter(r => r.food_id === foodId).map(r => {
            const ingredient = ingredients.find(ing => ing.id === r.ingredient_id);
            return {
                name: ingredient ? ingredient.name : 'Unknown Ingredient',
                quantity: r.quantity,
                ingredient_id: r.ingredient_id,
            };
        });

        const food = foods.find(f => f.id === foodId);
        if (food) {
            const selectedRecipeDetails = {
                id: food.id,
                name: food.name,
                ingredients: recipeIngredients,
                output: food.output,
            };
            setItemsProduced(food.output);
            setSelectedRecipe(selectedRecipeDetails);
            console.log("testing")
        } else {
            console.error('Food not found for ID:', foodId);
        }
    };

    const handleStockChange = (ingredientId: number, value: number) => {
        setStock({ ...stock, [ingredientId]: value });
        if (isAuthenticated) {
            if (saveTimeout.current) {
                clearTimeout(saveTimeout.current);
            }
            saveTimeout.current = setTimeout(() => {
                saveStockToDatabase(ingredientId, value);
            }, 1000);
        }
    };

    const handleCostChange = (ingredientId: number, value: number) => {
        setCosts({ ...costs, [ingredientId]: value });
        if (saveTimeout.current) {
            clearTimeout(saveTimeout.current);
        }
        saveTimeout.current = setTimeout(() => {
            saveCostToDatabase(ingredientId, value);
        }, 1000);
    };

    const saveStockToDatabase = async (ingredientId: number, value: number) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/ingredient/stock`, { ingredientId, value });
        } catch (error) {
            console.error('Error saving stock:', error);
        }
    };

    const saveCostToDatabase = async (ingredientId: number, value: number) => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/ingredient/cost`, { ingredientId, value });
        } catch (error) {
            console.error('Error saving cost:', error);
        }
    };

    const calculateCraftableRecipes = (): number => {
        if (!selectedRecipe) return 0;
        return selectedRecipe.ingredients.reduce((min, ingredient) => {
            const available = stock[ingredient.ingredient_id] || 0;
            const possible = Math.floor(available / ingredient.quantity);
            return Math.min(min, possible);
        }, Infinity);
    };

    const calculateNeededIngredients = (): { name: string; needed: number; ingredient_id: number }[] => {
        if (!selectedRecipe || productionRun <= 0) return [];
        return selectedRecipe.ingredients.map(ingredient => ({
            name: ingredient.name,
            needed: Math.max(0, ingredient.quantity * productionRun - (stock[ingredient.ingredient_id] || 0)),
            ingredient_id: ingredient.ingredient_id,
        }));
    };

    const calculateTotalCost = (): number => {
        const neededIngredients = calculateNeededIngredients();
        const ingredientCost = neededIngredients.reduce((total, ingredient) => {
            const cost = costs[ingredient.ingredient_id] || 0;
            return total + ingredient.needed * cost;
        }, 0);
        return ingredientCost + (craftingCost * (productionRun + (kickback - productionRun)));
    };

    const calculateProfit = (): number => {
        const totalCost = calculateTotalCost();
        const beforeKickbackRevenue = productionRun * itemsProduced * sellPrice;
        const kickbackRevenue = (kickback - productionRun) * itemsProduced * sellPrice;
        return beforeKickbackRevenue + kickbackRevenue - totalCost;
    };

    const calculateSellPrice = (): number => {
        const totalRevenue = (productionRun + (kickback - productionRun)) * itemsProduced * sellPrice;
        return totalRevenue;
    };

    const formatNumber = (number: number): string => {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    };

    const handleProductionRun = (value: number | null) => {
        if (value !== null) {
            setProductionRun(value);
            if (kickback < value) {
                setKickback(value);
            }
        }
    };

    return (
        <>
            <Title level={4}>Recipe Calculator</Title>
            {messageText && (
                <Alert message={messageText} type="error" />
            )}
            <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Col span={8}>
                    <Text>Select Recipe</Text>
                </Col>
                <Col span={8}>
                    <Select onChange={handleRecipeChange} style={{ width: '100%' }}>
                        <Option value="">Select a recipe</Option>
                        {foods.map(food => (
                            <Option key={food.id} value={food.id}>{food.name}</Option>
                        ))}
                    </Select>
                </Col>
            </Row>

            {selectedRecipe && (
                <>
                    <Title level={5}>{selectedRecipe.name}</Title>
                    <TableIngredient
                        ingredients={selectedRecipe.ingredients}
                        stock={stock}
                        costs={costs}
                        handleCostChange={handleCostChange}
                        handleStockChange={handleStockChange}
                    />
                    <Title level={5}>Craftable Recipes: {calculateCraftableRecipes()}</Title>

                    <Row gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                            <Text>Production Run</Text>
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={1}
                                value={productionRun}
                                onChange={handleProductionRun}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                            <Text>Items Produced per Run</Text>
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={1}
                                value={itemsProduced}
                                onChange={(value) => setItemsProduced(value as number)}
                                style={{ width: '100%' }}
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                            <Text>Sell Price per Item</Text>
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={0}
                                value={sellPrice}
                                onChange={(value) => setSellPrice(value as number)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                            <Text>Crafting Cost per Run</Text>
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={0}
                                value={craftingCost}
                                onChange={(value) => setCraftingCost(value as number)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16} style={{ marginBottom: '16px' }}>
                        <Col span={8}>
                            <Text>Total Runs After Kickback</Text>
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={productionRun}
                                value={kickback}
                                onChange={(value) => setKickback(value as number)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>

                    <Title level={5}>Needed Ingredients</Title>
                    <Table dataSource={calculateNeededIngredients()} pagination={false} rowKey="ingredient_id" bordered size={'small'}>
                        <Table.Column title="Ingredient" dataIndex="name" key="name" />
                        <Table.Column title="Needed" dataIndex="needed" key="needed" />
                    </Table>

                    <Table dataSource={[{ key: '1', label: 'Total Cost', value: formatNumber(calculateTotalCost()) }, { key: '2', label: 'Sales', value: formatNumber(calculateSellPrice()) }, { key: '3', label: 'Profit', value: formatNumber(calculateProfit()) }]} pagination={false} rowKey="key" bordered>
                        <Table.Column title="" dataIndex="label" key="label" />
                        <Table.Column title="" dataIndex="value" key="value" align="right" />
                    </Table>
                </>
            )}
        </>
    );
};

export default LandingPage;
