// Login.tsx

import React, {useState} from "react";
import {useAuthContext} from "../Auth/AuthContext";
import {useOutletContext, useNavigate} from 'react-router-dom'
import axios from "axios";
import {Input, Button, Space, Row, Col} from 'antd'

axios.defaults.withCredentials = true;

const Login: React.FC = () => {
    const [ userName, setUserName] = useState('');
    const [ password, setPassword] = useState('');
    const { setIsAuthenticated, setIsAdmin } = useAuthContext()
    const { setAlertMessage, setAlertClassName} = useOutletContext<any>();
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/authenticate`, {
                userName,
                password
            }, {
                withCredentials: true
            });

            console.log('Auth response:', response); // Debugging line

            if (response.data && response.data.error === false) {
                const sessionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/checksession`, {
                    withCredentials: true,
                });

                console.log('Session response:', sessionResponse); // Debugging line

                if (sessionResponse.status === 200 && sessionResponse.data) {
                    setIsAuthenticated(sessionResponse.data.isAuthenticated);
                    setIsAdmin(sessionResponse.data.isAdmin);
                    navigate('/');
                }
            } else {
                setAlertMessage(response.data.message);
                setAlertClassName("alert-danger");
            }
        } catch (error) {
            console.error('Error:', error);
            setAlertMessage("Failed to connect to backend. Please try again.");
            setAlertClassName("alert-danger");
        }
    };;

    return (
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col>
                <div style={{ textAlign: 'center' }}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                        <Space direction="vertical" size="middle">
                            <Input
                                placeholder="username"
                                name="email"
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <Input.Password
                                placeholder="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Space>
                    </form>
                </div>
            </Col>
        </Row>
    )
}

export default Login