// ProtectedRoute.tsx

import React from 'react';
import { useAuthContext } from './AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    const { isAuthenticated } = useAuthContext();

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return element;
};

export default ProtectedRoute;
