// TableIngredient.tsx

import {Ingredient} from "../Types/IngredientType";
import { Table, InputNumber } from 'antd';

interface tableProps {
    ingredients: {
        name: string,
        quantity: number,
        ingredient_id: number,
    }[];
    stock: { [ key: string]: number};
    costs: { [ key: string]: number};
    handleStockChange: (ingredientId: number, value: number) => void;
    handleCostChange: (ingredientId: number, value: number) => void
}

const TableIngredient: React.FC<tableProps> = ({
   ingredients,
   stock,
   costs,
   handleStockChange,
   handleCostChange,
}) => {
    return (
        <Table
            dataSource={ingredients}
            pagination={false}
            rowKey="ingredient_id"
            bordered
            size="small"
        >
            <Table.Column title="Ingredient" dataIndex="name" key="name" />
            <Table.Column title="Quantity" dataIndex="quantity" key="quantity" />
            <Table.Column
                title="Stock"
                key="stock"
                render={(text, record: { ingredient_id: number }) => (
                    <InputNumber
                        min={0}
                        value={stock[record.ingredient_id] || 0}
                        onChange={(value) => handleStockChange(record.ingredient_id, value as number)}
                    />
                )}
            />
            <Table.Column
                title="Cost per Unit"
                key="cost"
                render={(text, record: { ingredient_id: number }) => (
                    <InputNumber
                        min={0}
                        value={costs[record.ingredient_id] || 0}
                        onChange={(value) => handleCostChange(record.ingredient_id, value as number)}
                    />
                )}
            />
        </Table>
    );
}

export default TableIngredient