import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Table, Alert } from 'react-bootstrap';
import {useAuthContext} from "./Auth/AuthContext";
import {useNavigate} from "react-router-dom";

interface Food {
    id: number;
    name: string;
    tier: number;
}

interface Ingredient {
    id: number;
    name: string;
    tier: number;
}

interface Recipe {
    foodName: string;
    ingredients: { name: string; quantity: number }[];
}

interface RawRecipe {
    id: number;
    food_id: number;
    ingredient_id: number;
    quantity: number;
}

const FoodIngredientAdder: React.FC = () => {
    const [foods, setFoods] = useState<Food[]>([]);
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [selectedFood, setSelectedFood] = useState<number | null>(null);
    const [selectedIngredients, setSelectedIngredients] = useState<{ id: number, quantity: number }[]>([]);
    const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);
    const {isAuthenticated} =  useAuthContext()
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [foodResponse, ingredientResponse] = await Promise.all([
                    axios.get<Food[]>(`${process.env.REACT_APP_API_URL}/food`),
                    axios.get<Ingredient[]>(`${process.env.REACT_APP_API_URL}/ingredient`)
                ]);
                setFoods(foodResponse.data);
                setIngredients(ingredientResponse.data);
                fetchRecipes(foodResponse.data, ingredientResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const fetchRecipes = (foods: Food[], ingredients: Ingredient[]) => {
        axios.get<RawRecipe[]>(`${process.env.REACT_APP_API_URL}/recipe`)
            .then(response => {
                const rawRecipes = response.data;
                const formattedRecipes: Recipe[] = [];

                rawRecipes.forEach(rawRecipe => {
                    const food = foods.find(f => f.id === rawRecipe.food_id);
                    const ingredient = ingredients.find(ing => ing.id === rawRecipe.ingredient_id);

                    if (food && ingredient) {
                        let recipe = formattedRecipes.find(r => r.foodName === food.name);
                        if (!recipe) {
                            recipe = { foodName: food.name, ingredients: [] };
                            formattedRecipes.push(recipe);
                        }
                        recipe.ingredients.push({
                            name: ingredient.name,
                            quantity: rawRecipe.quantity
                        });
                    }
                });

                setRecipes(formattedRecipes);
            })
            .catch(error => console.error('Error fetching recipes:', error));
    };

    const handleFoodChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedFood(parseInt(event.target.value));
    };

    const handleIngredientChange = (index: number, event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const newSelectedIngredients = [...selectedIngredients];
        const { name, value } = event.target;

        if (name === 'ingredient') {
            newSelectedIngredients[index] = { ...newSelectedIngredients[index], id: parseInt(value) };
        } else if (name === 'quantity') {
            newSelectedIngredients[index] = { ...newSelectedIngredients[index], quantity: parseInt(value) };
        }

        setSelectedIngredients(newSelectedIngredients);
    };

    const addIngredientField = () => {
        setSelectedIngredients([...selectedIngredients, { id: 0, quantity: 1 }]);
    };

    const removeIngredientField = (index: number) => {
        const newSelectedIngredients = selectedIngredients.filter((_, i) => i !== index);
        setSelectedIngredients(newSelectedIngredients);
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (selectedFood === null) {
            setMessage({ text: 'Please select a food', type: 'error' });
            return;
        }

        console.log(JSON.stringify({ selectedFood, selectedIngredients }, null, 2));

        try {
            const foodName = foods.find(f => f.id === selectedFood)?.name || 'Unknown Food';
            const newRecipe: Recipe = {
                foodName,
                ingredients: selectedIngredients.map(si => {
                    const ingredientName = ingredients.find(ing => ing.id === si.id)?.name || 'Unknown Ingredient';
                    return { name: ingredientName, quantity: si.quantity };
                })
            };

            for (const ingredient of selectedIngredients) {
                await axios.post(`${process.env.REACT_APP_API_URL}/addrecipe`, {
                    food_id: selectedFood,
                    ingredient_id: ingredient.id,
                    quantity: ingredient.quantity,
                });
            }

            setRecipes([...recipes, newRecipe]);
            setMessage({ text: 'Ingredients added successfully', type: 'success' });
        } catch (error) {
            console.error('Error adding ingredients:', error);
            setMessage({ text: 'Failed to add ingredients', type: 'error' });
        }
    };

    return (
        <Container>
            <h1>Add Ingredients to Food</h1>
            {message && (
                <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
                    {message.text}
                </Alert>
            )}
            <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Select Food</Form.Label>
                    <Col sm={10}>
                        <Form.Select onChange={handleFoodChange}>
                            <option value="">Select a food</option>
                            {foods.map(food => (
                                <option key={food.id} value={food.id}>{food.name}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>

                {selectedIngredients.map((ingredient, index) => (
                    <Form.Group as={Row} className="mb-3" key={index}>
                        <Form.Label column sm={2}>Ingredient {index + 1}</Form.Label>
                        <Col sm={4}>
                            <Form.Select
                                name="ingredient"
                                value={ingredient.id}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => handleIngredientChange(index, e)}
                            >
                                <option value="">Select an ingredient</option>
                                {ingredients.map(ing => (
                                    <option key={ing.id} value={ing.id}>{ing.name}</option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <Form.Control
                                type="number"
                                name="quantity"
                                value={ingredient.quantity}
                                min="1"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleIngredientChange(index, e)}
                            />
                        </Col>
                        <Col sm={2}>
                            <Button variant="danger" onClick={() => removeIngredientField(index)}>Remove</Button>
                        </Col>
                    </Form.Group>
                ))}

                <Button variant="primary" onClick={addIngredientField}>Add Ingredient</Button>
                <Button variant="success" type="submit" className="ms-2">Save</Button>
            </Form>

            <h2 className="mt-4">Recipes</h2>
            <Table bordered hover>
                <thead>
                <tr>
                    <th>Food Name</th>
                    <th>Ingredients</th>
                </tr>
                </thead>
                <tbody>
                {recipes.map((recipe, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td rowSpan={recipe.ingredients.length + 1}>{recipe.foodName}</td>
                        </tr>
                        {recipe.ingredients.map((ing, i) => (
                            <tr key={i}>
                                <td>{ing.name} - {ing.quantity}</td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default FoodIngredientAdder;