//index.tsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from "./components/ErrorPage";
import LandingPage from "./components/LandingPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import IngredientManagement from "./components/IngredientManagement";
import FoodManagement from "./components/Food";
import FoodIngredientAdder from "./components/Recipe";
import {AuthProvider} from "./components/Auth/AuthContext";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import Login from "./components/Pages/Login";

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <LandingPage />
            },
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'ingredients',
                element: (
                    <ProtectedRoute element={<IngredientManagement />}/>
                ),
            },
            {
                path: 'foods',
                element: (
                    <ProtectedRoute element={<FoodManagement />}/>
                ),
            },
            {
                path: 'recipe',
                element: <FoodIngredientAdder />
            }
        ]

    }
])

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <AuthProvider>
        <RouterProvider router={router} />
    </AuthProvider>
);

reportWebVitals();
