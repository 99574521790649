// ErrorPages.tsx

import {Link, useRouteError} from "react-router-dom";
import {ErrorPageTypes} from "./Types/ErrorPageTypes";

export default function ErrorPage() {
    const error = useRouteError() as ErrorPageTypes;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <h1 className="mt-3">Oops!!</h1>
                    <p>The following error occurred:</p>
                    <p>
                        <em>{error.statusText || error.message}</em>
                    </p>
                    <Link to="/" className="btn btn-danger">Return to previous page</Link>
                </div>
            </div>
        </div>
    )
}
