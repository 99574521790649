import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import NavBar from "./components/Navbar/Navbar";
import { ConfigProvider, Layout } from "antd";
import { useAuthContext } from "./components/Auth/AuthContext";
import axios from "axios";
import AlertForm from "./components/Alert/Alert";
import AOFooter from "./components/Footer/Footer";
import AOHeader from "./components/Header/Header";

const { Header, Content, Footer } = Layout;

function App() {
    const { isAuthenticated, setIsAuthenticated } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertClassName, setAlertClassName] = useState("d-none");

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/protected`, {
                    withCredentials: true,
                });
                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, [setIsAuthenticated]);

    if (loading) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }

    return (
        <ConfigProvider>
            <Layout style={{ minHeight: '100vh' }}>
                <AOHeader />
                <Content style={{ padding: '0 50px', marginTop: '20px', flex: '1 0 auto', paddingBottom: '80px' }}>
                    <div style={{ padding: 24, background: '#ffffff', minHeight: 360 }}>
                        <Outlet context={{ setAlertMessage, setAlertClassName }} />
                    </div>
                </Content>
                <AOFooter />
            </Layout>
        </ConfigProvider>

    );
}

export default App;
