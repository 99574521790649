import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuProps } from "antd";
import {
    AppstoreAddOutlined,
    EditOutlined,
    LoginOutlined,
    LogoutOutlined,
    UsergroupAddOutlined
} from "@ant-design/icons";
import { useAuthContext } from "../Auth/AuthContext";
import axios from "axios";

const NavBar: React.FC = () => {
    const [current, setCurrent] = useState('main');
    const { isAuthenticated, isAdmin, setIsAuthenticated, setIsAdmin } = useAuthContext();
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };

    const logoutFunction = async (setIsAuthenticated: (value: boolean) => void, navigate: Function) => {
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/logout`, {
                withCredentials: true,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsAuthenticated(false);
            setIsAdmin(false);
            navigate('/');
        }
    };

    const handleLogout = () => {
        logoutFunction(setIsAuthenticated, navigate);
    };

    const items: MenuProps['items'] = [
        {
            key: 'main',
            icon: <AppstoreAddOutlined style={{ color: 'black' }} />,
            label: <Link to={'/'} style={{ color: 'black', textDecoration: 'none' }}>Main</Link>
        },
        isAuthenticated ? {
            key: 'ingredient',
            icon: <EditOutlined style={{ color: 'black' }} />,
            label: <Link to={'/ingredients'} style={{ color: 'black', textDecoration: 'none' }}>Ingredients</Link>
        } : undefined,
        isAuthenticated ? {
            key: 'foods',
            icon: <EditOutlined style={{ color: 'black' }} />,
            label: <Link to={'/foods'} style={{ color: 'black', textDecoration: 'none' }}>Dishes</Link>
        } : undefined,
        isAuthenticated ? {
            key: 'recipe',
            icon: <EditOutlined style={{ color: 'black' }} />,
            label: <Link to={'/recipe'} style={{ color: 'black', textDecoration: 'none' }}>Recipes</Link>
        } : undefined,
        isAdmin ? {
            key: 'userAdmin',
            icon: <UsergroupAddOutlined style={{ color: 'black' }} />,
            label: <Link to={'/useradmin'} style={{ color: 'black', textDecoration: 'none' }}>User Admin</Link>
        } : undefined,
    ].filter(Boolean) as MenuProps['items'];


    return (
        <Menu
            theme="light"
            mode="horizontal"
            style={{ background: '#dcd8cc', color: 'black', width: '100%' }}
            onClick={onClick}
            selectedKeys={[current]}
        >
            <Menu.Item key="brand" style={{ fontWeight: 'bold', cursor: 'default' }}>
                AO Food Proffesor
            </Menu.Item>
            {items?.map(item => (
                item && 'key' in item && 'icon' in item && 'label' in item ? (
                    <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                    </Menu.Item>
                ) : null
            ))}
            <Menu.Item key="spacer" disabled style={{ flex: 1, cursor: 'default' }} />
            {!isAuthenticated ? (
                <Menu.Item key="login" icon={<LoginOutlined style={{ color: 'black' }}/>}>
                    <Link to="/login" style={{ color: 'black', textDecoration: 'none' }}>Login</Link>
                </Menu.Item>
            ) : (
                <Menu.Item key="logout" icon={<LogoutOutlined style={{ color: 'black' }}/>} onClick={handleLogout}>
                    Logout
                </Menu.Item>
            )}
        </Menu>
    );




};

export default NavBar;
