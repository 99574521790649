import React, { forwardRef } from "react"
import {AlertProps} from "./AlertTypes";


const AlertForm = forwardRef<HTMLInputElement, AlertProps>((props, ref) => {
    return (
        <div className={`alert ${props.className}`} role="alert">
            {props.message}
        </div>
    );
});

export default AlertForm;