// Food.tsx

import React, {useEffect, useState, ChangeEvent } from "react";
import {Food} from "./Types/FoodTypes";
import axios from "axios";
import {Button, Container, Alert} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Input from "./Form/Form";

const addFoodType = async (name: string, tier: number):Promise<void> => {
    try {
        const response = await axios.post<Food>(`${process.env.REACT_APP_API_URL}/addfood`, {
            name,
            tier,
        });
        console.log('Food type added:', response.data);
    } catch (error) {
        console.error('Error adding food type:', error);
        throw error;
    }
}

const editFoodType = async (id: number, name: string, tier: number):Promise<void> => {
    try {
        const response = await axios.post<Food>(`${process.env.REACT_APP_API_URL}/editfood`, {
            id,
            name,
            tier,
        });
    } catch (error) {
        console.log(error)
        throw error;
    }
}

const deleteFoodType = async (id: number, name: string, tier: number):Promise<void> => {
    try {
        const response = await axios.post<Food>(`${process.env.REACT_APP_API_URL}/delfood`, {
            id,
            name,
            tier,
        });
    } catch (error) {
        console.log(error)
        throw error;
    }
}

const FoodManagement: React.FC = () => {
    const [ food, setFood ] = useState<Food[]>([]);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [tier, setTier] = useState('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchFoodItems();
    }, []);

    const handleAddClick = async ()  => {
        setLoading(true)
        if (!name) {
            setError('Name is required');
            return;
        }

        const tierNumber = parseInt(tier, 10);
        if (isNaN(tierNumber)) {
            setError('Tier must be a valid number');
            return;
        }
        try {
            await addFoodType(name, tierNumber);
            await fetchFoodItems();
        } catch (error) {
            console.log(error)
        }
    };

    const handleSaveClick = async (foodID: number, foodName: string, foodTier: number)  => {
        if (foodName === "") {
            setError("Name is required");
            console.log(error);
        }
        try {
            await editFoodType(foodID, foodName, foodTier);
            await fetchFoodItems();
        } catch (error) {
            console.log(error)
        }

        console.log(foodID, foodName, foodTier);

    }

    const handleDeleteClick = async (foodID: number, foodName: string, foodTier: number) => {
        setLoading(true);
        try {
            await deleteFoodType(foodID, foodName, foodTier)
            await fetchFoodItems();
        } catch (error) {
            console.log(error)
        }
    }

    const handleInputChange = (id: number, event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFood(prevFood =>
            prevFood.map(f =>
                f.id === id ? { ...f, [name]: name === "tier" ? parseInt(value) : value } : f
            )
        );
    };

    const fetchFoodItems = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/food`);
            setFood(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching food items:", error);
            setLoading(false);
        }
    };

    return (
        <>
        <Container>
            <h2>Dish Management</h2>
            <h4>Add Dish</h4>
            {error && <Alert variant="danger">{error}</Alert>}
            <Table>
                <thead>
                <tr>
                    <th style={{ width: '75%' }}></th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="name">Food Name</span>
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                onChange={(f) => setName(f.target.value)}>
                            </input>
                        </div>

                    </td>
                    <td>
                        <div className="input-group mb-2">
                            <span className="input-group-text" id="tier">Tier</span>
                            <input
                                type="number"
                                min="1"
                                max="8"
                                className="form-control"
                                name="tier"
                                onChange={(e) => setTier(e.target.value)}>
                            </input>
                        </div>
                    </td>
                    <td>
                        <div className="input-group mb-2">
                            <button
                                className="btn btn-outline-primary"
                                onClick={handleAddClick}>
                                Add
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
            <h4>Edit Dishes</h4>
            <Table bordered hover responsive striped>
                <thead>
                <tr>
                    <th style={{ width: '70%' }}>Name</th>
                    <th>Tier</th>
                    <th style={{ width: '20%' }}>Action</th>
                </tr>
                {food.map(f => (
                    <tr key={f.id}>
                        <td>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={f.name}
                                    name="name"
                                    onChange={(e) => handleInputChange(f.id, e)}>
                                </input>
                            </div>
                        </td>
                        <td>
                            <div className="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    max="8"
                                    className="form-control"
                                    value={f.tier}
                                    name="tier"
                                    onChange={(e) => handleInputChange(f.id, e)}>
                                </input>
                            </div>
                        </td>
                        <td className="align-middle">
                            <div>
                                <Button
                                    className="me-1"
                                    variant="outline-primary"
                                    style={{marginTop: '6px'}}
                                    onClick={() => handleSaveClick(f.id, f.name, f.tier)}>
                                    Save
                                </Button>
                                <Button
                                    className="me-1"
                                    variant="outline-danger"
                                    style={{ marginTop: '6px' }}
                                    onClick={() => handleDeleteClick(f.id, f.name, f.tier)}>
                                    Delete
                                </Button>
                            </div>
                        </td>
                    </tr>
                ))}
                </thead>
            </Table>
        </Container>
        </>
    )
}

export default FoodManagement