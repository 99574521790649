// SimpleInput.tsx

import {forwardRef, ChangeEvent} from "react";
import {Form} from "react-bootstrap";

interface SimpleInputProps {
    name: string;
    className?: string;
    value?: string | number;
    type: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    errorMsg?: string
    min?: string;
    max?: string;

}

const SimpleInputForm = forwardRef<HTMLInputElement, SimpleInputProps>((props, ref) => {
    return (
        <Form.Group className="mb-3" controlId={props.name}>
            <Form.Control
                type={props.type}
                className={props.className}
                ref={ref}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                min={props.min}
                max={props.max}
            />
            <Form.Control.Feedback type="invalid">
                {props.errorMsg}
            </Form.Control.Feedback>
        </Form.Group>
    )
})

export default SimpleInputForm