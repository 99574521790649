// Footer.tsx

import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AOFooter: React.FC = () => {
    return (
        <Footer style={{
            textAlign: 'center',
            flexShrink: 0,
            background: '#dcd8cc',
            position: 'fixed',
            bottom: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the footer content
            height: '60px' // specify a fixed height
        }}>
            Made using React, Ant Design and Go running as backend by mancert @2024<br/>
            Playing as Sigriid on EU servers
        </Footer>
    );
};

export default AOFooter;